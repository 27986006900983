import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link,graphql } from 'gatsby'
import { Table } from 'antd';
import "antd/dist/antd.css";

import Layout from "../components/layout"
import Seo from "../components/seo"
import { ListLink, ListItem } from '../components/common/lists'
import useHeadings from '../lib/useHeadings'
import Phone from '../components/common/phone'
import { schedule, parking } from '../lib/tables'
import { StyledH3 } from '..//styles/custom'


const FaqPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  const tableOfContents = useHeadings();
  // console.log("tableOfContents", tableOfContents)

  return (
    <Layout location={location} title={siteTitle} toc={tableOfContents}>
    <Seo title="よくある質問" />

    <h1>よくある質問</h1>

    <h2 id="診療全般について">診療全般について</h2>


    <StyledH3>○○○ではないかと思うのですが、診てもらえますか？</StyledH3>


    ○○○にはいる病名に応じて、回答が異なります。
  
    <p>
    一般的な精神疾患(<strong>統合失調症、感情障害、認知症、不安障害</strong>など)については、当院にて診断ならびに治療が可能です。
    ただし、以下のような疾患については、当院の対応には限界があります。
    </p>

    <p>
    <strong>注意欠陥多動障害(ADHD)</strong>については、限定的な対応となります。
    正確な診断には詳細な病歴聴取と専門的な知識が必要となるため、他の専門機関に紹介します。
    もし他院ですでに確定診断をされている場合には、原則として当院でも治療継続は可能です。
    ただし当院で処方できる薬物は、ストラテラやインチュニブのみとなっています。
    </p>

    <p>
    各種依存症(<strong>アルコール依存症、薬物依存症</strong>など)についても、限定的な対応です。
    診断は当院で可能ですが、治療は専門機関(主に東北会病院)に紹介します。
    </p>

    <p>
    <strong>性同一性障害</strong>については、当院では対応が困難です。
    なお、患者さんの希望で以前に何度か東北大学病院精神科に紹介していました。
    ただ同院からの返事にて「(東北大学は)性同一性障害に関する専門チームや認定医を有していないので、手術等に必要な書類を作成することはできません」という趣旨の回答がありました。そのため今後は、大学病院等への紹介はいたしません。
    </p>

    <p>
    <strong>知的障害</strong>については、臨床的な診断は可能ですが、詳細な診断は他院やアーチルなどに心理テストを依頼することになります。
    </p>

    <p>
    ほかにも最近になって提唱され、まだその疾患概念が臨床現場で十分に検証されていないような疾患が多数あります。
    こうした新規な疾患についても、対応には限界があります。
    </p>

    <StyledH3>○○○は、処方できますか？</StyledH3>

    一般的な抗精神病薬や向精神薬の処方は、可能です。
    ただ、流通管理規制がかかっている下記の薬剤は、処方できません。当院はADHD適正流通システムに登録していないからです。
    
    <ListItem 
    items={[
      "メチルフェニデート製剤(リタリン、コンサータ)", 
      "モダフィニル製剤(モディオダール)", 
      "リスデキサンフェタミン製剤(ビバンセ)", 
      "ブプレノルフィン経皮吸収型製剤(ノルスパンテープ)"
      ]}
    >
    </ListItem>

    なお処方の可否は、専門家としての医師の裁量によって判断されます。
    上記以外の薬であっても、患者さんの要望に応えられない場合があります。

    <StyledH3>未成年でも受診できますか？</StyledH3>

    <p>
    未成年(満18歳未満)の方の場合は、少なくとも初診時は保護者同伴での受診をお願いしています。
    もし事情があって保護者の同伴ができない場合でも、受診する事実を保護者の方に必ず知らせておいてください。
    </p>

    <p>
    なお当院では小学生以下の方の対応は困難となっております。
    もし受診した場合には、原則として専門機関(宮城県立子ども病院、せんだんホスピタルなど)に紹介するという対応をおこなっています。
    </p>

    
    <StyledH3>生活保護を受給中ですが、受診できますか？</StyledH3>

    <p>
    当院は生活保護指定医療機関ですので、生活保護を受給中の方も受診できます。
    </p>

    <p>
    生活保護制度は、日本国民に保障されている「健康で文化的な最低限度の生活を営む権利」(<a href="http://elaws.e-gov.go.jp/search/elawsSearch/elaws_search/lsg0500/detail?lawId=321CONSTITUTION&openerCode=1#26"> 憲法25条 </a>)を具体化したものです。
    </p>

    <p>
    その権利のひとつとして、生活保護を受給中の方は生活保護指定医療機関にて医療扶助(病気やケガの治療に必要な費用)のサービスを自己負担なしで受けることができるのです。
    </p>

    <p>
    受診の際には、受付にて診察依頼書あるいは生活保護支給票を提示していただくか、「生活保護を受給中である」ことをお伝えください。
    </p>

    <StyledH3>診察で話した内容は、外部に漏れませんか？</StyledH3>

    <p>
    医師には、以下のような内容の守秘義務が課せられています(<a href="http://elaws.e-gov.go.jp/search/elawsSearch/elaws_search/lsg0500/detail?lawId=140AC0000000045#594"> 刑法第１３４条第１項</a>)。
    そのため診療で知り得た個人情報は、正当な理由がないかぎり、外部に知られることはありません。
    </p>

    <pre>
    医師、薬剤師、医薬品販売業者、助産師、弁護士、弁護人、公証人又はこれらの職にあった者が、

    正当な理由がないのに、その業務上取り扱ったことについて知り得た人の秘密を漏らしたときは、

    六月以下の懲役又は十万円以下の罰金に処する。
    </pre>

    <StyledH3>受診前に相談できますか？</StyledH3>

    <p>
    保険診療として受診する前に、受診が必要かどうかを相談することは可能です。
    ただし、受診前相談はスマートフォンを利用したスマホ診療でのみ実施しています。

    詳細は、<Link to="/online#受診前に相談したい場合"> スマホ診療について </Link> のページを参照ください。
    </p>

    <StyledH3>他院にて治療中ですが、受診できますか？</StyledH3>

    <p>
    当院への転医(通院先を変更すること)を希望する場合には、受診は可能です。初診として予約を入れてください。可能であれば、前医に紹介状を書いてもらえると助かります。
    </p>

    <p>
    一方で当院への転医を希望するわけではなく、前医の治療や診断に関する意見をご希望の場合にはセカンドオピニオンでの受診となります。セカンドオピニオンは保険適用外なので、自費診療となります。
    当院の場合には、<Link to="/online#受診前に相談したい場合"> スマホ診療の受診前相談 </Link> として実施しています。
    </p>

    <StyledH3>セカンドオピニオンとして利用できますか？</StyledH3>

    <p>
    すでに他院で治療中でその治療内容を相談したい場合(セカンドオピニオン)には、スマートフォンを利用したスマホ診療の受診前相談をご利用ください。

    詳細は、<Link to="/online#受診前に相談したい場合"> スマホ診療の受診前相談 </Link> のページを参照ください。
    </p>

    <StyledH3>家族や交際相手も別に受診できますか？</StyledH3>

    <p>
      当院では、同一家族のメンバーを診ることは原則としてありません。
      なぜならば家族同士はしばしば感情的に複雑な関係を有しており、場合によっては互いに意見や利害が対立する恐れがあるからです。 そうした場合に私が片方の肩を持てばもう一方の患者さんとの関係が悪化する懸念があります。そのリスクを回避するため、こうした運用としています。
    </p>
    <p>
      同じ理由により、交際関係にある患者さんを双方とも診ることは避けています。
    </p>

    <h2 id="初診の手続きについて">初診の手続きについて</h2>


    <StyledH3>初診に予約は必要ですか？</StyledH3>

    <p>
    初診には、予約が必要です。
    診療時間中は再来と新患の診療でほぼ終日全て埋まっており、予約せずに来院されても対応できません。
    </p>

    <StyledH3>どのように初診を予約したらよいですか？</StyledH3>

    <p>
    初診の予約は、<strong>当日分のみ</strong>電話にて受け付けています。
    新患予約の電話対応の時間は、午前10時から正午12時までです。
    電子メールでは新患の予約を受け付けていないので、ご注意ください。

    初診として受診可能な時間帯は、以下のようになります。
    </p>

    <Table dataSource={schedule.newPatient.dataSource} columns={schedule.newPatient.columns} size="small" pagination={false} bordered />

    予約の際には、下記の情報を伝えてください。

    <ListItem 
    items={[
      "氏名", 
      "性別", 
      "年齢", 
      "電話番号", 
      "現在の状況(1分程度で)"]}
    >
    </ListItem>


    <Phone id="phone"></Phone>

    <StyledH3>初診で受診する際に必要なものはなんでしょうか？</StyledH3>

    <p>
    有効な保険証をお持ちの方は、その<strong>保険証を持参</strong>してください。
    生活保護世帯の方は、診察依頼書あるいは生活保護支給票を持参してください。

    もしいずれも持っていない場合には、自費診療になる可能性があります。
    自費診療については、<Link to="/faq#保険証がないのですが、初診の費用はいくらになりますか？"> こちら </Link>を参照ください。
    </p>

    <StyledH3>初診の費用はいくらですか？</StyledH3>

    <p>
    初診にかかる費用は、診療点数と負担割合をもとにして計算されます。
    初診の診療点数は、20歳以上では、おおむね以下のように計算されます。
    なお診療点数1点=10円です。
    </p>

    <Table 
      columns={[
        {
          title: '項目',
          dataIndex: 'item',
          key: 'item',
        },
        {
          title: '診療点数',
          dataIndex: 'point',
          key: 'point',
        },
      ]} 
      dataSource={[
        {
          key: '1',
          item: '精神科初診料',
          point: "288+400点",
        },
        {
          key: '2',
          item: '処方せん料',
          point: "約70点",
        },
        {
          key: '3',
          item: '合計点数',
          point: "約758点",
        },
      ]} 
      size="small" 
      pagination={false} 
      bordered 
    />

    <p>
    この点数をもとにして窓口で支払う自己負担分が計算されます。
    例えば、自己負担割合が3割だと、初診時の支払い金額は以下のようになります。
    </p>

    <pre> 
    約758点 X 10 X 0.3 =  約2300円
    </pre>

    <p> 
      その他の条件で料金を算出したい場合には、<Link to="/tools"> ツール </Link> のページにある「料金シミュレータ」をご利用ください。
    </p> 

    <StyledH3 id="保険証がないのですが、初診の費用はいくらになりますか？">保険証がないのですが、初診の費用はいくらになりますか？</StyledH3>

    <p>
    保険証がない場合は、原則として自費診療となります(生活保護等の公費負担制度をのぞく)。
    その場合は、前記式での負担割合が10割になるので、初診時の支払いはおおむね以下の金額になります(成人の場合)。
    </p>

    <pre>
    約752点 X 10 X 1.0 =  約7500円
    </pre>

    <p>
    なお、入社直後でまだ手元に保険証がない場合もあります。
    その際には、保険証が手に入った時点で再度来院していただければ、前回受診時の自費診療分から自己負担分を差し引いた金額を返却いたします。
    </p>
    <p>
    あるいは正規の保険証を入手するまでのあいだ、<strong>健康保険被保険者資格証明書</strong>を利用して保険医療を受けることもできます。
    この証明書は、会社の所在地を管轄する年金事務所で取得することができます。
    詳しくは、<a href="http://www.nenkin.go.jp/service/kounen/jigyosho-hiho/hihokensha2/20131113.html"> 従業員に健康保険被保険者資格証明書を交付するときの手続き </a>等のページを参照ください。
    </p>

    <h2 id="再診について">再診について</h2>


    <StyledH3>再診に予約は必要ですか？</StyledH3>

    <p>
    再診には予約は必須ではありませんが、事前に予約を入れることをお勧めします。
    なぜならば、診察は原則として予約患者さんを優先するので、予約がない患者さんは待ち時間が長くなる場合があるからです。
    予約は、電子メールもしくは電話にて受けつけています。

    再診として予約する場合には、メールもしくは電話にて以下の情報を教えてください。
    比較的空いた時間をお伝えし、予約を入れます。
    </p>

    <ListItem 
    items={[
      "診察券に記載されているID番号", 
      "氏名(フルネーム)", 
      "受診を希望する日時", 
    ]}
    >
    </ListItem>


    <StyledH3>長めに話を聞いてもらえますか？</StyledH3>

    <p>
    当院では5分程度の診療時間を想定してスケジュールを組んでいます(他の医療機関も同様の運用だと思います)。
    もし混みいった相談があって長めの診療時間を希望する場合には、特別に30分程度の診療時間を確保します。
    あらかじめ予約をとるときに「長めの診察を希望します」とご相談ください。
    </p>

    <h2 id="紹介状について">紹介状について</h2>

    <StyledH3>転医に際して前医の紹介状は必要ですか？</StyledH3>

    <p>
    通院するクリニックを当院に変更したい場合、元の医療機関(前医)の紹介状は必要ではありません。
    なぜならば、日本の医療制度では患者さんには医療機関を選択する自由があり、診察を求められた医師には原則として診察に応じる法的義務(応召義務)があるからです。
    </p>

    <p>
    ただし、前医の紹介状があったほうが、病歴を把握しやすく、薬剤の選択にも有益です。
    もし前医にて紹介状を発行してもらえるのならば、受診の際にご提示ください。
    </p>

    <StyledH3>他の病院への紹介状を書いてもらえますか？</StyledH3>

    <p>
    ご本人が転医を希望する場合、転居で当地を離れる場合や当院にて治療が困難な症例には、他の医療機関を宛先とした紹介状を発行します。
    紹介状の準備に数日ほど時間が必要になるので、発行は後日になります。
    </p>

    <h2 id="各種書類について">各種書類について</h2>


    <StyledH3>診断書の料金はいくらですか？</StyledH3>

    <p>
    診断書は種類によって費用が異なります。
    詳細は、<Link to="/about#診療内容"> 診療内容のページ </Link> を参照ください。
    </p>


    <StyledH3>書類は、すぐに発行してもらえますか？</StyledH3>


    <p>
    書類の種類によって、発行にかかる日数は異なります。
    次の表を参照下さい。
    </p>

    <Table 
      columns={[
        {
          title: '診断書の種類',
          dataIndex: 'kind',
          key: 'kind',
        },
        {
          title: '作成に必要な日数',
          dataIndex: 'days',
          key: 'days',
        },
      ]} 
      dataSource={[
        {
          key: '1',
          kind: '診断書(当院書式)',
          days: "即日",
        },
        {
          key: '2',
          kind: '診断書(書式が指定されているもの)',
          days: "1週間程度",
        },
        {
          key: '3',
          kind: '診断書(英文)',
          days: "2週間程度",
        },
        {
          key: '4',
          kind: '自立支援医療診断書',
          days: "1週間程度",
        },
        {
          key: '5',
          kind: '福祉手帳診断書',
          days: "1週間程度",
        },
        {
          key: '6',
          kind: '障害年金診断書',
          days: "2週間程度",
        },
        {
          key: '7',
          kind: '就労可否証明書',
          days: "即日",
        },
        {
          key: '8',
          kind: 'ハローワーク主治医意見書',
          days: "1週間程度",
        },
        {
          key: '9',
          kind: '介護保険主治医意見書',
          days: "1週間程度",
        },
        {
          key: '20',
          kind: '成年後見制度診断書',
          days: "1週間程度",
        },
      ]} 
      size="small" 
      pagination={false} 
      bordered 
    />

    <StyledH3>各種免許の登録のための診断書も発行できますか？</StyledH3>

    <p>
    以下の免許登録に必要な診断書については、原則として対応可能です。
    </p>

    <ListLink 
      items={
        [
          <a href="https://www.pref.miyagi.jp/soshiki/iryou/shiken01-menkyo.html"> 医療従事者免許の新規申請の際の診断書 </a>,
          [
            "医師免許",
            "歯科医師免許",
            "保健師免許",
            "助産師免許",
            "看護師免許",
            "診療放射線技師免許",
            "臨床検査技師免許",
            "理学療法士免許",
            "視能訓練士免許",
          ],
          <a href="https://www.pref.miyagi.jp/soshiki/yakumu/yakuzaishi1.html"> 薬剤師免許申請の際に必要な診断書 </a>,
          <a href="http://www.zaijusei.com/doc/shindansho_2019.pdf"> 柔道整復師の名簿登録に必要な診断書 </a>,
          <a href="http://www.sb.rbc.or.jp/2006/11/post_7.html"> 美容師・理容師の免許登録に必要な診断書 </a>,
          <a href="http://www.ahaki.or.jp/registration/data/shindansho.pdf"> あん摩マッサージ指圧師、はり師及びきゅう師の名簿登録に必要な診断書 </a>,
          <a href="https://www.pref.miyagi.jp/uploaded/attachment/743526.pdf"> 狩猟免許試験のための診断書 </a>,

        ]
      }
    >
    </ListLink>

    <p>
    診察時間は、おおむね20分から30分程度となります。
    ただし、当院での正確な診断が困難な場合には、専門機関への診断をお勧めすることもあります。

    各種免許のための診断書発行は、自費診療となります。
    その価格は、全て6,000円/1通です。
    これに初診料が加わるので、 受診時の料金は下記のようになります。
    </p>

    <pre>
    6000円 + 288点 X 10 =  約8900円(税込)
    </pre>

    <h2 id="交通手段について">交通手段について</h2>


    <StyledH3>最寄り駅はどちらですか？</StyledH3>
  
    <p>
    一番近い駅は、地下鉄東西線の<strong>宮城野通駅</strong>です。北2番出口から出て、徒歩1分程度です。
    <strong>仙台駅東口</strong>からも、徒歩6分程度となっています。
    なお、実際にかかる時間は、選択した経路や信号機の状態によって多少の誤差があります。
    </p>

    <StyledH3>駐車場はありますか？</StyledH3>

    <p>
    現時点では、当院専用の駐車場は<strong>ありません</strong>。
    近隣にいくつかパーキングがありますので、そちらをご利用ください。
    </p>

    <Table dataSource={parking.dataSource} columns={parking.columns} size="small" pagination={false} bordered />


    <iframe 
      title={"近隣パーキング地図"}
      src="https://www.google.com/maps/d/u/1/embed?mid=14Y1ER9v_-jG4s7D8JVRDJYVA4sfvwEcw" 
      style={{marginTop: "2rem", marginBottom: "2rem", overflow: "hidden", height: "24rem", width: "100%" }}
      allowFullScreen
    >
    </iframe>

    <p>
    仙台駅東口にあるヨドバシカメラの裏口付近から周囲を見渡すと、通りの向こう側に大きなファミリーマートの店舗が見えます。
    その右隣りのSANKYOビルの2階が、仙台東口メンタルクリニックです。
    </p>

      <figure className="text-center">
        <StaticImage
          className=""
          layout="fullWidth"
          formats={["auto", "webp", "avif"]}
          src="../images/street.png"
          quality={95}
          alt="アクセスマップ"
        />
        <figcaption>
          通りからの外観
        </figcaption>
      </figure>


    </Layout>
  )
}

export default FaqPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
    // allFile {
    //   edges {
    //     node {
    //       name
    //       childImageSharp {
    //         gatsbyImageData(layout: CONSTRAINED)
    //       }
    //     }
    //   }
    // }
